<template>
  <NuxtLayout>
    <div class="page-index">
      <div
        v-for="element in componentList"
        :key="element.id"
        class="component_layout"
      >
        <index-banner
          v-if="element.type === 'banner'"
          :component-info="element"
        />
        <index-course
          v-if="element.type === 'course'"
          :component-info="element"
        />
        <index-grade
          v-if="element.type === 'grade'"
          :component-info="element"
        />
        <index-teacher
          v-if="element.type === 'teacher'"
          :component-info="element"
        />
        <index-news
          v-if="element.type === 'news'"
          :component-info="element"
        />
        <index-ad
          v-if="element.type === 'ad'"
          :component-info="element"
        />
        <index-patch-ad
          v-if="element.type === 'patchAd'"
          :component-info="element"
        />
        <index-exam-prac
          v-if="element.type === 'examPrac'"
          :component-info="element"
        />
        <index-book
          v-if="element.type === 'book'"
          :component-info="element"
        />
      </div>
    </div>
  </NuxtLayout>
</template>
<script setup>
import { getComponentList, getComponentData } from '@/api/layouts'
import cookie from '@/utils/cookies'
import config from '~/config'
import { shopDataInfo, testDebug } from '../api/system'

const { data: shopData } = await useAsyncData('course', async (ctx) => {
  let url = ''
  if (process.server) {
    url = ctx.ssrContext?.event?.req?.headers?.host
  } else {
    url = window.location.host
  }
  if (config.url) {
    url = config.url
  }
  return await shopDataInfo({ domain: url })
})

const cookieData = cookie.getShop()

useHead({
  meta: [{ charset: 'utf-8' }, {
    hid: 'keywords',
    name: 'keywords',
    content: shopData.value.keywords || cookieData.shopName
  },
  {
    hid: 'description',
    name: 'description',
    content: shopData.value.shopIntroduce || cookieData.shopName
  }],
  title: computed(() => `${cookieData.shopName}`)
})

const opts = reactive({
  componentEnum: {
    1: { name: 'banner', listName: 'detailList' },
    2: { name: 'course', listName: 'courseList' },
    3: { name: 'grade', listName: 'classroomList' },
    4: { name: 'teacher', listName: 'teacherList' },
    5: { name: 'news', listName: 'articleList' },
    6: { name: 'ad', listName: 'detailList' },
    7: { name: 'patchAd', listName: 'detailList' },
    9: { name: 'examPrac', listName: 'dailyPracticeList' },
    10: { name: 'book', listName: 'bookList' },
  }
})
const componentList = ref([])
const getTemplate = async () => {
  const result = await getComponentList()
  console.log('result', result)
  componentList.value = []
  result.forEach((item) => {
    const c = opts.componentEnum[item.componentType]
    if (c && c.name) {
      const cp = {
        type: c.name,
        id: item.id,
        componentType: item.componentType,
        config: item,
        data: []
      }
      componentList.value.push(cp)
    }
  })
  let count = 0
  return new Promise((resolve) => {
    componentList.value.forEach(async (item) => {
      try {
        const res = await getComponentData({ id: item.id })
        if (item.componentType === 9) {
          item.data = [
            ...res.chapterPracticeList,
            ...res.dailyPracticeList,
            ...res.paperExamList,
            ...res.passPracticeList
          ]
        } else {
          item.data = res[opts.componentEnum[item.componentType].listName] || []
        }
        item.navList = res.navList || []
      } catch (err) {
      }
      count++
      if (count === componentList.value.length) {
        resolve(componentList.value)
      }
    })
  })
}

onMounted(() => {
  getTemplate()
})
</script>
<style lang="scss" scoped>

</style>
