<template>
  <div
    v-if="visible"
    class="ad w"
  >
    <div class="adShowType_four">
      <i
        class="iconfont icon-cuowu font18 close_img"
        @click.stop="close"
      />
      <custom-link
        :to="handleNavigate(componentInfo.config)"
        :target-type="componentInfo.config.targetType"
        :target-open-new-page="componentInfo.config.targetOpenNewPage"
      >
        <div class="adShowType_four_content">
          <div
            v-if="componentInfo.config.imageUrl"
            class="item_image_wrapper"
          >
            <img
              class="item_image"
              :src="componentInfo.config.imageUrl"
            >
          </div>
          <div
            v-if="componentInfo.config.patchAdStyle === 2"
            class="patch-ad-content"
          >
            <span>{{ componentInfo.config.patchAdContent }}</span>
          </div>
          <img
            class="close_btn"
            src="@/assets/pc/close.png"
            alt=""
            @click.prevent="visible = false"
          >
        </div>
      </custom-link>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'

export default defineComponent({
  props: {
    componentInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup (props, { emit }) {
    const visible = ref(true)
    const close = () => {
      visible.value = false
    }
    return {
      visible,
      close
    }
  }
})
</script>

<style lang="scss" scoped>
.ad {
  position: relative;
  padding: 25px 0px;
  overflow: hidden;
  font-size: 0;

  .adShowType_four {
    height: 100%;
    position: relative;

    .close_img {
      position: absolute;
      right: 8px;
      top: 8px;
      z-index: 10;
      color: #becadb;
    }
    .adShowType_four_content {
      position: relative;
      height: 100%;
      margin-bottom: 15px;
      overflow: hidden;
      width: 100%;
      .patch-ad-content {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        color: #fff;
      }
      .close_btn {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
    }
    .adShowType_four_content:last-child {
      margin-bottom: 0;
    }
  }

  .item_image_wrapper {
    width: 100%;
    height: 100px;
    position: relative;
    overflow: hidden;
    .item_image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .close_img {
      position: absolute;
      right: 8px;
      top: 4px;
      color: #becadb;
    }
  }
}
</style>
