<template>
  <div class="news_wrapper w">
    <div
      v-if="componentInfo.config.showTitle"
      class="news_title"
    >
      <div class="news_title_name">
        <index-title-icon />
        <span>{{ componentInfo.config.title }}</span>
      </div>
      <NuxtLink to="/paper/list">
        <div
          v-if="componentInfo.config.showMore"
          class="pointer"
        >
          更多<i class="arrows" />
        </div>
      </NuxtLink>
    </div>
    <div class="btn_list mgb20">
      <el-button
        v-for="item in navTypeList"
        :key="item.navCode"
        plain
        class="btn"
        :class="{active: activeBtn === item.navCode}"
        @click="changeNav(item.navCode)"
      >
        {{ item.navName }}
      </el-button>
    </div>
    <div class="new_main">
      <NuxtLink
        v-for="item in dataList"
        :key="item.id"
        :to="item.practiceType ? `/practice/view?id=${item.id}` : `/paper/view?id=${item.id}`"
        class="news_item_wrapper"
      >
        <div class="news_item">
          <div class="item_img">
            <img src="@/assets/exam_prac.png">
          </div>
          <div class="item_info">
            <div class="title">
              {{ item.practiceName || item.paperName }}
            </div>
            <div class="do">
              <span class="mgr10">共{{ item.questionCount }}题</span>
              <span v-if="item.participateCount">共{{ item.participateCount }}人做过</span>
            </div>
            <div class="price">
              <span v-if="item.price !== 0" class="font12 mgr10">￥<span class="font20">{{ item.price }}</span></span>
              <span v-if="item.price !== 0" class="line_price">￥{{ item.linePrice }}</span>
              <span v-else class="font20">免费</span>
            </div>
          </div>
          <div class="item_btn">
            <el-button type="primary" class="btn">前往查看</el-button>
          </div>
        </div>
      </NuxtLink>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch, onMounted } from 'vue'
import { getNavTypeList } from '@/api/course'

export default defineComponent({
  props: {
    componentInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup (props, { emit }) {
    const activeBtn = ref(1)
    const dataList = ref([])
    const navTypeList = ref([])
    const changeNav = (num) => {
      activeBtn.value = num
      filterData()
    }
    const filterData = () => {
      console.log(props.componentInfo.data)
      if (activeBtn.value === 4) {
        dataList.value = props.componentInfo.data.filter(el => !el.practiceType)
      } else {
        dataList.value = props.componentInfo.data.filter(el => el.practiceType === activeBtn.value)
      }
    }
    watch(() => props.componentInfo.data, () => {
      filterData()
    })
    onMounted(() => {
      dataList.value = props.componentInfo.data
      getNavTypeList({ resourceNavType: 2 }).then(res => {
        navTypeList.value = res
      })
    })

    return {
      activeBtn,
      dataList,
      changeNav,
      navTypeList
    }
  }
})
</script>
<style lang="scss" scoped>
.btn {
  width: 92px;
  height: 35px;
  font-size: 16px;
  &.active {
    color: var(--el-button-hover-text-color);
    border-color: var(--el-button-hover-border-color);
    background-color: var(--el-button-hover-bg-color);
    outline: 0;
  }
}
.news_wrapper {
  padding: 25px 0px;
}
.news_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  line-height: 22px;
  color: #333;
}
.news_title_name {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  img {
    width: 24px;
    height: 24px;
  }
}
.new_main {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}
.news_item_wrapper {
  margin-bottom: 20px;
  padding: 0 10px;
  width: 50%;
}
.news_item_wrapper:nth-last-child(-n + 2) {
  margin-bottom: 0;
}
.news_item {
  padding: 15px;
  display: flex;
  background: #FFFFFF;
  border-radius: 5px;
  .item_img {
    width: 50px;
  }
  .item_info {
    flex: 1;
    .title {
      font-size: 16px;
      overflow : hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .do {
      color: #999999;
      margin: 10px 0;
    }
    .price {
      color: #F61818;
      .line_price {
        text-decoration: line-through;
        color: #999999;
      }
    }
  }
  .item_btn {
    // width: 80px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    .btn {
      width: 80px;
      height: 36px;
    }
  }
}
</style>
