<template>
    <div class="banner" :class="{'w': imageFillType === 1}" v-if="componentInfo.data.length > 0" :style="{'margin-top':imageFillType=== 1 ? '20px' : '0px'}" @mouseout="mout" @mouseover="mover">
      <div class="banner-content">
        <div class="w relative">
          <div :class="['classify_tree_wrapper',  imageFillType === 1 ? '' : 'type2']"  v-if="showNavMenu">
        <div class="classify_tree_main" >
          <classify-tree :class-list="navList" :style="'height:' + (imageFillType === 1 ? '416px' : '404px')"/>
        </div>
      </div>
        </div>
        <ul class="banner-list" :style="'height:' + (imageFillType === 1 ? '416px' : '456px') ">
          <li v-for="(item, index) in componentInfo.data" :key="index" class="item" :class="{ on: index === num }">
            <custom-link :to="handleNavigate(item)" :targetType="item.targetType" :targetOpenNewPage="item.targetOpenNewPage" >
              <div class="image_wrapper">
                <img class="image" :style="[{'border-radius':imageFillType=== 1 ? '10px' : '0px'},{'height' : (imageFillType === 1 ? '416px' : '456px')}]" :src="item.imageUrl" :alt="item.advTitle" />
              </div>
            </custom-link>
          </li>
        </ul>
        <div class="w relative">
          <ul class="page_dots">
            <span v-for="(item, index) in componentInfo.data" :key="index" class="dots" :class="{ on: index === num }" @click="num = index" @mouseenter="num = index" />
          </ul>
        </div>
      </div>
    </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue';
import ClassifyTree from "./ClassifyTree.vue"
export default defineComponent({
  components:{
    ClassifyTree
  },
  props: {
    componentInfo: {
      type: Object,
      default: () => {
        return {};
      },
    }
  },
  setup(props, { emit }) {
    onMounted(() => {
      change();
    });
    const imageFillType = computed(()=>{
      return props.componentInfo.config.imageFillType
    })



    const navList = computed(()=>{
      return props.componentInfo.navList
    })
    const showNavMenu = computed(()=>{
      return props.componentInfo.config.showNavMenu
    })
    const num = ref(0);
    const interval = ref(null);
    const change = () => {
      interval.value = setInterval(() => {
        if (num.value + 1 >= props.componentInfo.data.length) {
          num.value = 0;
        } else {
          num.value++;
        }
      }, 5000);
    };
    const mout = () => {
      change();
    };
    const mover = () => {
      clearInterval(interval.value);
    };
    return {
      num,
      interval,
      mout,
      mover,
      imageFillType,
      navList,
      showNavMenu
    };
  },
});
</script>
<style lang="scss">
   .banner {
    .banner-content {
      .image_wrapper{
        width: 100%;
        height: 100%;
      }
    }
   }
</style>
<style lang="scss" scoped>
.banner {
  position: relative;
  .banner-content {
    margin: 0 auto;
    position: relative;
    // background: #e3e3e3;
    overflow: hidden;
  }
  ul {
    position: relative;
  }
  .item {
    opacity: 0;
    height: 100%;
    background-position: center center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    transition: all 1s;
    text-align: center;
    &.on {
      opacity: 1;
      z-index: 10;
    }
    a {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .page_dots {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 15px;
    right: 15px;
    padding: 0px 15px 0;
    text-align: center;
    border-radius: 13px;
    height: 26px;
    // background: rgba(0, 0, 0, 0.2);
    z-index: 11;
    &.old {
      left: 0;
      right: 0;
      margin-right: 0;
      bottom: 30px;
    }
    .dots {
      display: inline-block;
      width: 8px;
      height: 6px;
      margin: 0 4px;
      background: #BECADB;
      border-radius: 15px;
      &.on {
        width: 14px;
        background: #2256F6;
      }
    }
  }
  .classify_tree_wrapper{
    z-index: 300;
    position: absolute;
    left: 0px;
    top: 0px;
    &:deep(.classify-tree>.wrapper)  {
      padding-top: 18px;
    }

    &.type2 {
      top: 26px;
      bottom: 26px;
      &:deep(.classify-tree>.wrapper)  {
        padding-top: 13px;
      }
    }
    .classify_tree_main{
      margin: auto;
      .classify_tree{
        width: 100%;
      }
    }
  }
}
.image_wrapper{
  width: 100%;
  height: 100%;
  .image{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

</style>
