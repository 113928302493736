<template>
  <div
    class="classify-tree"
    @mouseleave="handleMoveLeave"
  >
    <div
      class="wrapper"
      :class="{'active-wrapper': childrenList.length}"
    >
      <div
        v-for="(item, index) in classList"
        :key="index"
        class="tree"
        @mouseenter="handleChangeChidrenList(item)"
      >
        <custom-link
          class="tree-a"
          :to="handleNavigate(item)"
          :target-type="item.targetType"
          :target-open-new-page="item.targetOpenNewPage"
        >
          <div class="name">
            {{ item.navName }}
          </div>
          <i
            v-if="item.childrenList && item.childrenList.length"
            class="arrows mgr10"
          />
        </custom-link>
      </div>
    </div>
    <classify-tree
      v-if="childrenList.length"
      class="children_list"
      :class-list="childrenList"
    />
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'ClassifyTree',
  props: {
    classList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  setup (props) {
    const childrenList = ref([])
    const handleChangeChidrenList = (row) => {
      if (row.childrenList) {
        childrenList.value = row.childrenList
      } else {
        handleMoveLeave()
      }
    }
    const handleMoveLeave = () => {
      childrenList.value = []
    }
    return {
      childrenList,
      handleChangeChidrenList,
      handleMoveLeave
    }
  }
})
</script>

<style lang="scss">
.classify-tree {
  font-size: 14px;
  display: flex;
  .wrapper {
    padding:10px 0px 10px 30px;
    width: 224px;
    background: rgba(0, 0, 0, 0.65);;
    border-radius: 6px;
  }
  .active-wrapper{
    border-radius: 6px  0 0 6px;
  }
  .tree {

    padding: 17px 0px 17px 10px;

    .tree-a {
      display: flex;
      color: #fff;
      cursor: pointer;
      justify-content: space-between;
    }
    .name{
      line-height: 20px;
      font-size: 16px;
    }
  }
  .tree:hover{
    background: rgba(255, 255, 255, 0.1);
    border-radius: 6px 0px 0px 6px;
  }
  .children_list{
    .wrapper {
    padding:10px 0px 10px 10px;
    width: 180px;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 0px 6px 6px 0px;
  }
  .active-wrapper{
    border-radius: 0px;
  }
  .tree {
    padding: 17px 0px 17px 10px;
    .tree-a {
      display: flex;
      color: #fff;
      cursor: pointer;
      justify-content: space-between;
    }
  }
  .tree:hover{
    border-color: #2256F6;
    background: rgba(34, 86, 246, 0.05);
    border-radius: 6px 0px 0px 6px;
  }
  .tree:hover .tree-a {
    color: #2256F6;
  }
  }
}
</style>
