<template>
  <div class="custom_av w">
    <!-- 一行四个 -->
    <div
      v-if="componentInfo.config.imageAdStyle === 1"
      class="adShowType_one"
    >
      <custom-link
        v-for="item in componentInfo.data"
        :key="item.id"
        :to="handleNavigate(item)"
        :target-type="item.targetType"
        :target-open-new-page="item.targetOpenNewPage"
        class="adShowType_one_content"
      >
        <div>
          <div class="item_image_wrapper item_image">
            <img
              class="item_image"
              :src="item.imageUrl"
            >
          </div>
        </div>
      </custom-link>
    </div>
    <!-- 一行三个 -->
    <div
      v-if="componentInfo.config.imageAdStyle === 2"
      class="adShowType_two"
    >
      <custom-link
        v-for="item in componentInfo.data"
        :key="item.id"
        :to="handleNavigate(item)"
        :target-type="item.targetType"
        :target-open-new-page="item.targetOpenNewPage"
        class="adShowType_two_content"
      >
        <div>
          <div class="item_image_wrapper">
            <img
              class="item_image"
              :src="item.imageUrl"
            >
          </div>
        </div>
      </custom-link>
    </div>
    <!-- 一行两个 -->
    <div
      v-if="componentInfo.config.imageAdStyle === 3"
      class="adShowType_three"
    >
      <custom-link
        v-for="item in componentInfo.data"
        :key="item.id"
        :to="handleNavigate(item)"
        :target-type="item.targetType"
        :target-open-new-page="item.targetOpenNewPage"
        class="adShowType_three_content"
      >
        <div>
          <div class="item_image_wrapper">
            <img
              class="item_image"
              :src="item.imageUrl"
            >
          </div>
        </div>
      </custom-link>
    </div>
    <!-- 一行一个 -->
    <div
      v-if="componentInfo.config.imageAdStyle === 4"
      class="adShowType_four"
    >
      <custom-link
        v-for="item in componentInfo.data"
        :key="item.id"
        :to="handleNavigate(item)"
        :target-type="item.targetType"
        :target-open-new-page="item.targetOpenNewPage"
        class="adShowType_four_content"
      >
        <div>
          <div class="item_image_wrapper">
            <img
              class="item_image"
              :src="item.imageUrl"
            >
          </div>
        </div>
      </custom-link>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    componentInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup (props, { emit }) {
    return {}
  }
})
</script>

<style lang="scss">
.custom_av {
  padding: 25px 0px;
  overflow: hidden;
  font-size: 0;
  .adShowType_one {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    .adShowType_one_content {
      overflow: hidden;
      margin-bottom: 15px;
      width: 285px;
      height: 120px;
    }
    .adShowType_one_content:nth-last-child(-n + 4) {
      margin-bottom: 0;
    }
  }
  .adShowType_two {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    .adShowType_two_content {
      height: 120px;
      margin-bottom: 15px;
      overflow: hidden;
      width: 386px;
    }
    .adShowType_two_content:nth-last-child(-n + 3) {
      margin-bottom: 0;
    }
  }
  .adShowType_three {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    .adShowType_three_content {
      height: 120px;
      margin-bottom: 15px;
      overflow: hidden;
      width: 590px;
    }
    .adShowType_three_content:nth-last-child(-n + 2) {
      margin-bottom: 0;
    }
  }
  .adShowType_four {
    height: 100%;
    .adShowType_four_content {
      height: 100%;
      margin-bottom: 15px;
      overflow: hidden;
      padding: 0 8px;
      width: 100%;
    }
    .adShowType_four_content:last-child {
      margin-bottom: 0;
    }
  }
  .default_image_wrapper {
    width: 100%;
    height: 124px;
    overflow: hidden;
    background: #3d7fff12;
    display: flex;
    align-items: center;
    justify-content: center;
    .default_image {
      width: 40px;
      height: 40px;
    }
  }

  .item_image_wrapper {
    width: 100%;
    height: 120px;
    position: relative;
    overflow: hidden;
    .item_image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
